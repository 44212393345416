import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Section from '../components/section';
import TwoColumnSection from '../components/twoColumnSection';
import MemberCard from '../components/memberCard/MemberCard';

import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbAnchor,
} from '../components/breadcrumb';

type UberUnsProps = {
  pageContext: {
    language: string;
  };
  data: any;
};

const AboutUsDe = ({ pageContext, data }: UberUnsProps) => {
  const { language } = pageContext;
  const intl = useIntl();
  const aboutData = data.allPrismicAboutUs.edges[0].node.data;

  return (
    <div>
      <Layout lang={language}>
        <SEO title={aboutData.page_title} lang={language} />
        <Breadcrumb className="breadcrumb--workbench">
          <BreadcrumbAnchor
            label={intl.formatMessage({
              id: 'home',
              defaultMessage: 'home',
            })}
            url="/"
          />
          <BreadcrumbDivider />
          <BreadcrumbAnchor active label={aboutData.page_title} />
        </Breadcrumb>
        <Section
          className="hero hero--about"
          title={aboutData.page_title}
          description={aboutData.page_headline}
        />

        <Section className="dt-about__section" title={aboutData.mission_title}>
          <TwoColumnSection
            toggleDirection
            description={`<blockquote>${aboutData.statements[0].title}</blockquote><p>${aboutData.statements[0].text_one}</p>`}
            cover={aboutData.statements[0].image.url}
            cover_alt={'Mission statement'}
          />
          <TwoColumnSection
            title={aboutData.statements[1].title}
            description={aboutData.statements[1].text_one}
            cover={aboutData.statements[1].image.url}
            cover_alt={aboutData.statements[1].title}
          />
        </Section>

        <Section
          className="dt-about__section dt-about__section--managers"
          title={aboutData.team_title}
        >
          {aboutData.team_members.map((member, idx) => (
            <MemberCard
              key={`member-${member.name}-${idx}`}
              description={member.description}
              fullName={member.name}
              avatar={member.image.url}
              linkedin={member.linkedin_url}
              position={member.job_title}
            />
          ))}
        </Section>
      </Layout>
    </div>
  );
};

export const query = graphql`
  query AboutPageQuery($prismicLang: String) {
    allPrismicAboutUs(filter: { lang: { eq: $prismicLang } }) {
      edges {
        node {
          _previewable
          id
          lang
          data {
            mission_title
            page_headline
            page_title
            statements {
              image {
                url
              }
              text_one
              title
            }
            team_members {
              description
              image {
                alt
                url
              }
              job_title
              linkedin_url
              name
            }
            team_title
          }
        }
      }
    }
  }
`;

/* eslint-disable-next-line */
// @ts-ignore eslint-disable-line
export default withPrismicPreview(AboutUsDe);
