import React from 'react';
import LinkedinIcon from '../../assets/icons/linkedin.svg';

type MemberCardProps = {
  avatar: string;
  fullName: string;
  position: string;
  description: string;
  linkedin: string;
};

const MemberCard = ({
  avatar,
  fullName,
  position,
  description,
  linkedin,
}: MemberCardProps) => {
  return (
    <article className="dt-member-card">
      <figure className="dt-member-card__avatar">
        <img src={avatar} className="dt-member-card__img" alt={fullName} />
      </figure>
      <div className="dt-member-card__content">
        <h3 className="dt-member-card__name">{fullName}</h3>
        <h4 className="dt-member-card__position">{position}</h4>
        <p className="dt-member-card__description">{description}</p>
        <a
          className="dt-member-card__linkedin"
          href={linkedin}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={LinkedinIcon} alt="LinkedIn" />
        </a>
      </div>
    </article>
  );
};

export default MemberCard;
